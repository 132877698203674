<template>
  <div class="doughnut">    

    <h4 class="text-center mt-2 mb-2"><b>{{'Number of Feedback Received'}}</b></h4>
    
    <vue-apex-charts
      ref="doughnut_chart"
      type="donut"
      :height="height"
      :options="chartOptions"
      :series="series"
      v-show="show"
    />

    <b-row v-show="show == false">

        <b-col md="12">
            <b-card no-body class="p-1">
                <h5 class="text-center">No Data Available</h5>
            </b-card>
        </b-col>

    </b-row>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { POST_API } from "../../../store/actions.type";
import moment from "moment-timezone";
import { $themeColors } from '@themeConfig'

export default {
  components: { VueApexCharts },

    props: ['siteData', 'location', 'start', 'end', 'title', 'legend_position'],

    watch: { 
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        }        
    },

  data() {
    return {
        height:400,
        show:false,
        series: [1,1],
        chartOptions: {
            labels: ['Satisfied', 'Unsatisfied'],
            stroke: {
                width: 0,
                colors: undefined
            },
            chart: {
                type: 'donut',
            },
            colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.info, $themeColors.secondary],
            legend: {
                position: this.legend_position,
                fontFamily: 'Montserrat',
                labels: {
                    colors: ['#ffffff'],
                },
            },
            dataLabels: { 
                enabled: true,
                formatter: function (val, opt) {
                    return Number((val).toFixed(2))+"%"
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Montserrat',
                                color: '#dfsda',
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontFamily: 'Montserrat',
                                color: '#ffffff',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                offsetY: 16,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                color: '#ffffff',
                                fontSize: '30px',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0)
                                }
                            }
                        }
                    }
                }
            },
        },
    }
  },

  methods: {
        updateSeries() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                },
                api: '/api/feedback-request-analytics-doughnut'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.series[0] = parseInt(this.$store.getters.getResults.data[0].value);
                    this.series[1] = parseInt(this.$store.getters.getResults.data[1].value);
                    //console.log(this.series,this.series[0] + this.series[1]);
                    if (typeof this.$refs['doughnut_chart'] != 'undefined') {
                        
                        this.$refs.doughnut_chart.updateSeries(this.series, true);
                        this.$refs.doughnut_chart.updateOptions(this.chartOptions, true);
                        if ((this.series[0] + this.series[1]) > 0) {
                            this.show = true;
                        }else {
                            this.show = false;
                        }
                        

                    }
                    //console.log(typeof this.$refs['doughnut_chart'],this.show);
                    //this.$emit('setCount',this.series);
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.updateSeries();
            }
        }
    },

    mounted() {
        this.updateSeries();
    }
}
</script>

<style lang="scss"></style>
