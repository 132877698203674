<template>
    <div>
        <h4 class="text-center mt-2 mb-2"><b>{{'Graphical Chart'}}</b></h4>
        
        <b-row v-if="graphicalData.length > 0">

            <b-col md="4" class="text-center" v-for="(gd, ind) in graphicalData" :key="ind">
                <b-card>
                    <ChartComponent :graphical="{data: gd.percentage, image: gd.image, color: gd.color}"/>
                    <h5 class="text-center">{{gd.label}} - {{gd.data}}</h5>
                </b-card>
            </b-col>

        </b-row>

        <b-row v-if="graphicalData.length == 0">

            <b-col md="12">
                <b-card no-body class="p-1">
                    <h5 class="text-center">No Data Available</h5>
                </b-card>
            </b-col>

        </b-row>


        
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import ChartComponent from './ChartComponent';
import {
        BCard, BRow, BCol
    } from 'bootstrap-vue'
export default {
    name: 'PieChart',
    components: { VueApexCharts, ChartComponent,BRow,BCol,BCard},

    props: ['siteData', 'location', 'start', 'end', 'legend_position'],

    watch: { 
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        }        
    },

    data(){
        return{    
            graphicalData : []

        }
    },

    methods: {
        getData() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                },
                api: '/api/feedback-request-graphical-charts'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.graphicalData = this.$store.getters.getResults.data
                    
                    return this.graphicalData
                    
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.getData();
            }
        }
    },

    mounted() {
        this.getData();
    }
}
</script>

<style>

</style>