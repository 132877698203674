<template>
    <div>

        <h4 class="text-center mt-2 mb-2"><b>{{'Nature Breakdown'}}</b></h4>
        
        <b-row v-show="show == true">

            

            <b-col md="8" offset-md="2">

                <vue-apex-charts
                    type="pie"
                    :height="height"
                    class="mt-0 mb-1"
                    :options="options"
                    :series="series"
                    ref="nbd_chart"
                    v-show="show"
                />
            </b-col>
            
        </b-row>

        <b-row v-show="show == false">

            <b-col md="12">
                <b-card no-body class="p-1">
                    <h5 class="text-center">No Data Available</h5>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import {
  BRow, BCol
} from 'bootstrap-vue'

export default {
    name: 'PieChart',
    components: { VueApexCharts,BRow, BCol },

    props: ['siteData', 'location', 'start', 'end'],

    watch: { 
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        }        
    },

    data(){
        return{ 
            show:false,
            height:400,   
            themeColor: '#FFFFFF',
            series:[],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                labels: [],

                dataLabels: {
                    enabled: true,
                },
                legend: {
                    show: true,
                    position: 'left',
                    fontFamily: 'Montserrat',
                    labels: {
                        colors: ['#ffffff'],
                    },
                },
                stroke: {
                    width: 0,
                },
                colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
            },

        }
    },

    methods: {
        updateSeries() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                },
                api: '/api/feedback-request-analytics-bar'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.options.labels = this.$store.getters.getResults.data.labels
                    this.series = this.$store.getters.getResults.data.series;
                    
                    this.options.colors = [];

                    if (this.series.length > 0) {

                        for (var i = 0; i < (this.series.length); i++) {
                        
                            var randomColor = Math.floor(Math.random()*16777215).toString(16);
                            
                            

                            if (randomColor.length == 6) {
                                
                                this.options.colors.push('#'+randomColor);
                            }else{
                                i = i - 1;
                            }
                        }    
                    }

                    // console.log(this.options.colors,'aa');

                    if (this.options.labels.length > 0) {
                        this.show = true;
                        this.$refs.nbd_chart.updateSeries(this.series, true);
                        this.$refs.nbd_chart.updateOptions(this.options, true);    
                    }else{
                        this.show = false;
                    }
                    
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.updateSeries();
            }
        }
    },

    mounted() {
        this.updateSeries();
    }
}
</script>

<style>

</style>