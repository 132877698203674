<template>
    <div>
        <b-table sticky-header
            ref="refUserListTable"
            class="position-relative rounded"
            :items="dataList"
            responsive
            :fields="tableColumns"
            show-empty
            empty-text="No matching records found"
        >
            <!-- <template #cell(trigger_count)="items">
                <td v-if="items.item.legend == 'Total Impressions'" rowspan="3">{{ items.item.trigger_count }}</td>
                <p v-else>{{ items.item.trigger_count }}</p>
            </template> -->
        </b-table>
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, 
        VBTooltip, BForm, BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BFormFile, BFormGroup, 
        BFormCheckbox, BCardBody, BBreadcrumb
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import moment from "moment-timezone";

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
            BPagination, BForm, vSelect, BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, 
            BFormFile, BFormGroup, BFormCheckbox, BBreadcrumb
        },
        props: ['siteData', 'location', 'start', 'end'],
        watch: { 
            siteData: function(newVal, oldVal) {
                this.filterTable();
            },
            location: function(newVal, oldVal) {
                this.filterTable();
            },
            start: function(newVal, oldVal) {
                this.filterTable();
            },
            end: function(newVal, oldVal) {
                this.filterTable();
            },
        },
        data() {
            return {
                tableColumns: [
                    { key: 'legend', label: '', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'trigger_count', label: 'Trigger Count', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'attend_time', label: 'Attend Time (Average)', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'resolution', label: 'Resolution', sortable: false , thStyle:  {width: '25%'}},
                ],
                items  : [],
                webUrl : process.env.VUE_APP_SERVER_URL,
                error_message : null,
                showDismissibleAlert : false,

                data : [
                    {
                        legend: '8am - 10pm',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: '24 Hours',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: 'Total Impressions',
                        trigger_count: '0%'
                    }
                ],
            }
        },
        methods : {
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.siteData ? this.siteData._id : null,
					    location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
					    end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-table'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.data = this.$store.getters.getResults.data;
                        return this.data;
                    }
                });
            },

            filterTable(){

                if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                    this.$refs.refUserListTable.refresh();
                }
            }    
        },
        mounted(){}
  
    }
</script>
