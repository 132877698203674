<template>
    <div>
        <h4 class="text-center mt-2 mb-2"><b>{{'8am - 10pm'}}</b></h4>

        <vue-apex-charts
            type="pie"
            height="280"
            class="mt-0 mb-1"
            :options="options"
            :series="series"
            ref="pie_chart"
            v-if="(series[0] + series[1]) > 0"
        />

        <b-row v-if="(series[0] + series[1]) == 0">

            <b-col md="12">
                <b-card no-body class="p-1">
                    <h5 class="text-center">No Data Available</h5>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";

export default {
    name: 'PieChart',
    components: { VueApexCharts },

    props: ['siteData', 'location', 'start', 'end', 'legend_position'],

    watch: { 
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        }        
    },

    data(){
        return{    
            themeColor: '#FFFFFF',
            series:[],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                labels: [],

                dataLabels: {
                    enabled: true,
                },
                legend: {
                    show: true,
                    position: this.legend_position,
                    fontFamily: 'Montserrat',
                    labels: {
                        colors: ['#ffffff'],
                    },
                },
                stroke: {
                    width: 0,
                },
                colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
            },

        }
    },

    methods: {
        updateSeries() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                },
                api: '/api/feedback-request-analytics-pie-8-10'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.options.labels = this.$store.getters.getResults.data.labels
                    this.series = this.$store.getters.getResults.data.series;
                    
                    this.options.colors = [$themeColors.warning, $themeColors.success];
                    if(typeof this.$refs['pie_chart'] != 'undefined'){

                        this.$refs.pie_chart.updateSeries(this.series, true);
                        this.$refs.pie_chart.updateOptions(this.options, true);    
                    }
                    
                    
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.updateSeries();
            }
        }
    },

    mounted() {
        this.updateSeries();
    }
}
</script>

<style>

</style>