<template>
    <div>
        <!-- <b-button title="Export PDF" variant="warning" @click="exportPDF();" class="float-right"> -->
        <!-- <b-button title="Export PDF" variant="warning" @click="exportFile();" class="float-right"> -->
        <b-button title="Export PDF" variant="warning" @click="openModel();" class="float-right">
            Export PDF
        </b-button>
        <!-- <div v-if="false"> -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1398"
            filename="Analytics_Report"
            :pdf-quality="3"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="landscape"
            pdf-content-width="1065px"
            @progress="onProgress($event)"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="bg-white">

                <section class="pdf-item">
                    <b-row v-if="organization_logo || site_logo">
                        <b-col cols="6">
                            <img v-if="site_logo" :src="site_logo" alt="Logo" width="110" height="110" loading="eager"/>
                        </b-col>
                        <b-col cols="6">
                            <img v-if="organization_logo" :src="organization_logo" alt="Logo" width="110" height="110" class="float-right" loading="eager"/>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" class="text-center"><strong><span style="color: #000 !important; font-size: 15px;">Analytics</span></strong></b-col>
                    </b-row>
                    <b-row style="color: #000 !important; font-size: 12px !important;" class="pb-2 pt-1">
                        <b-col cols="3" class="my-font"><strong>Project Site</strong></b-col>
                        <b-col cols="3" class="my-font">: {{ site && site.site_name ? site.site_name : '' }}</b-col>                    
                        <b-col cols="3" class="my-font"><strong>Location</strong></b-col>
                        <b-col cols="3" class="my-font">: {{location.name}}</b-col>

                        <b-col cols="3" class="my-font"><strong>Start Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{start}}</b-col>
                        <b-col cols="3" class="my-font"><strong>End Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{end}}</b-col>

                        <b-col cols="3" class="my-font"><strong>Generated By</strong></b-col>
                        <b-col cols="3" class="my-font">: {{this.$store.getters.currentUser.full_name}}</b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col cols="6" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <!-- <b-table striped responsive
                                    ref="refUserListTable" class="rounded"
                                    :items="dataList" :fields="tableColumns"                                
                                >
                                </b-table> -->
                                <div class="border-dark" style="overflow: hidden;">
                                    <table class="table table-bordered table-dark rounded">
                                        <thead>
                                            <tr>
                                            <th scope="col"></th>
                                            <th scope="col">TRIGGER COUNT</th>
                                            <th scope="col">ATTEND TIME (AVERAGE)</th>
                                            <th scope="col">RESOLUTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">8am - 10pm</th>
                                                <td> {{ data[0].trigger_count }} </td>
                                                <td> {{ data[0].attend_time }} </td>
                                                <td> {{ data[0].resolution }} </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">24 Hours</th>
                                                <td>{{ data[1].trigger_count }}</td>
                                                <td>{{ data[1].attend_time }}</td>
                                                <td>{{ data[1].resolution }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total Impressions</th>
                                                <td colspan="3">{{ data[2].trigger_count }}</td>
                                            </tr>
                                        </tbody>  
                                    </table>
                                </div>
                            </b-card>
                        </b-col>

                        <b-col cols="3" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div>
                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'8am - 10pm'}}</b></h5>

                                    <vue-apex-charts
                                        type="pie"
                                        height="300"
                                        class="mt-0 mb-1"
                                        :options="optionsH1"
                                        :series="seriesH1"
                                        ref="pie_chartH1"
                                        v-if="(seriesH1[0] + seriesH1[1]) > 0"
                                    />

                                    <b-row v-if="(seriesH1[0] + seriesH1[1]) == 0">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>

                        <b-col cols="3" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div>
                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'24 Hours'}}</b></h5>

                                    <vue-apex-charts
                                        type="pie"
                                        height="300"
                                        class="mt-0 mb-1"
                                        :options="optionsH2"
                                        :series="seriesH2"
                                        ref="pie_chartH2"
                                        v-if="(seriesH2[0] + seriesH2[1]) > 0"
                                    />

                                    <b-row v-if="(seriesH2[0] + seriesH2[1]) == 0">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </section>
                <div class="html2pdf__page-break"/>

                <section class="pdf-item">
                    <b-row>
                        <b-col cols="6" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div class="doughnut">    

                                <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'Number of Feedback Received'}}</b></h5>

                                <vue-apex-charts
                                ref="doughnut_chart"
                                type="donut"
                                height="450"
                                :options="chartOptionsNFR"
                                :series="seriesNFR"
                                v-show="showNFR"
                                />

                                <b-row v-show="showNFR == false">

                                    <b-col md="12">
                                        <b-card no-body class="p-1">
                                            <h5 class="text-center">No Data Available</h5>
                                        </b-card>
                                    </b-col>

                                </b-row>

                                </div>
                            </b-card>
                        </b-col>
                        
                        <b-col cols="6" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div class="doughnut">    

                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'Nature Breakdown'}}</b></h5>

                                    <vue-apex-charts
                                        ref="nbd_chart"
                                        type="pie"
                                        height="450"
                                        class="mt-0 mb-1"
                                        :options="optionsNBD"
                                        :series="seriesNBD"
                                        v-show="showNBD"
                                    />

                                    <b-row v-show="showNBD == false">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>

                                </div>
                            </b-card>
                        </b-col>

                    </b-row>
                </section>
                <div class="html2pdf__page-break"/>
                
                <section class="pdf-item">
                    <b-row>
                        <!-- Nature of Trigger -->
                        <b-col cols="12" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div>
                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'Nature of Trigger'}}</b></h5>
                                    <vue-apex-charts
                                        ref="bar_chartNT"
                                        type="bar"
                                        height="550"
                                        :options="optionsNT"
                                        :series="seriesNT"
                                        v-show="showNT"
                                    />

                                    <b-row v-show="showNT == false">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </section>

                <div class="html2pdf__page-break"/>
                
                <section class="pdf-item">
                    <b-row>
                        <b-col cols="12" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div>
                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'Trigger By Locations'}}</b></h5>
                                    <vue-apex-charts
                                        ref="loc_chart"
                                        type="bar"
                                        height="550"
                                        :options="optionsTBL"
                                        :series="seriesTBL"
                                        v-show="showTBL"
                                    />

                                    <b-row v-show="showTBL == false">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </section>
                <div class="html2pdf__page-break"/>

                <section class="pdf-item">
                    <b-row>
                        <!-- Daily Representation -->
                        <b-col cols="12" offset-md="0" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div title="">
                                    <h5 class="text-center mt-2 mb-2 dark-text">{{'Daily Representation'}}</h5>
                                    <vue-apex-charts
                                        ref="line_chartDR"
                                        type="area"
                                        height="550"
                                        :options="chartOptionsDR"
                                        :series="seriesDR"
                                        v-show="showDR"
                                    />

                                    <b-row v-show="showDR == false">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </section>

                <div class="html2pdf__page-break"/>
                
                <section class="pdf-item">
                    <b-row>
                        <!-- Graphical Chart -->
                        <b-col cols="12" offset-md="0" class="pb-2">
                            <b-card no-body class="h-100 bg-white">
                                <div>
                                    <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'Graphical Chart'}}</b></h5>
                                    
                                    <b-row v-if="graphicalData.length > 0">

                                        <b-col md="3" class="text-center" v-for="(gd, ind) in graphicalData" :key="ind">
                                            <b-card class="bg-white">
                                                <!-- {{ gd.chartOptions.plotOptions.radialBar.hollow.image }} -->

                                                <!-- <vue-apex-charts type="radialBar" height="180" :options="gd.chartOptions" :series="gd.series" /> -->
                                                <ChartComponentPDF :graphical="{data: gd.percentage, image: gd.image, color: gd.color}"/>
                                                <h5 class="text-center dark-text pt-1">{{gd.label}} - {{gd.data}}</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>

                                    <b-row v-if="graphicalData.length == 0">

                                        <b-col md="12">
                                            <b-card no-body class="p-1">
                                                <h5 class="text-center">No Data Available</h5>
                                            </b-card>
                                        </b-col>

                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row v-if="main_logo">
                        <b-col cols="12">
                            <img v-if="main_logo" :src="main_logo" alt="Logo" width="110" loading="eager"/>
                        </b-col>
                    </b-row>
                </section>

            </section>
        </vue-html2pdf>
        <!-- </div> -->

        <b-modal
            id="chart-modal"
            ref="chart-modal"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Export Chart PDF"
            @show="resetModal"
            @hidden="resetModal"
            size="lg"
            hide-footer
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-form-group label="Select Chart" class="required">
                    <!-- <b-form-checkbox 
                        class="custom-control-warning" 
                        @change="selectAllGraph"
                        value="yes"
                        unchecked-value="no"
                        v-model="select_all"
                    > Select All 
                    </b-form-checkbox> -->

                    <!-- @change="updateSelectAll" -->
                    <!-- <b-form-checkbox-group
                        v-model="chart_type"
                        :options="chart_types"
                        stacked
                        class="custom-padding"
                    ></b-form-checkbox-group> -->
                    <b-row>
                        <b-col cols="6" v-for="(chart, ind) in chart_types" :key="ind" class="pt-1">
                            <b-form-checkbox
                                v-model="chart.value"
                                :value="true"
                                :unchecked-value="false"
                            >
                                {{chart.title}}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                </b-form-group>
            </b-form>

            <footer class="modal-footer pt-1 pl-0 pr-0 pb-0">
                <b-button class="" variant="outline-secondary" @click="resetModal">Close</b-button>
                <b-button class="ml-1 " variant="outline-warning" @click="getChartPDF">Submit</b-button>
            </footer>

        </b-modal>

    </div>
</template>

<script>
    import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BCardBody, BFormSelectOption, BFormSelect, BFormTextarea, BForm, BFormRadio, 
        BBreadcrumb, BFormRadioGroup, BTabs, BTab, BFormCheckboxGroup
	} from 'bootstrap-vue';
    import { POST_API } from "../../../store/actions.type";
    import moment from "moment-timezone";
	import VueApexCharts from 'vue-apexcharts';
    import VueHtml2pdf from 'vue-html2pdf';
	import Bus from "../../../event-bus";
    import { $themeColors } from '@themeConfig'
    import ChartComponentPDF from './ChartComponentPDF';

    export default {
        name: 'ExportReport',
		components: {
			BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
            BPagination, BFormCheckbox, BFormSelect, BFormSelectOption, VueApexCharts, BFormTextarea, BAlert, BFormGroup, BForm, 
			BFormRadio, BBreadcrumb, VueHtml2pdf, BFormRadioGroup, BTabs, BTab, ChartComponentPDF, BFormCheckboxGroup
		},
        
        watch: { 
            site: function(newVal, oldVal) {
                this.site = newVal;
                /* this.getHeaderImages().then(() => {
                    this.dataList().then(() => {
                        this.updateSeriesH1().then(() => {
                            this.updateSeriesH2().then(() => {
                                this.updateSeriesNFR().then(() => {
                                    this.updateSeriesNBD().then(() => {
                                        this.updateSeriesNT().then(() => {
                                            this.updateSeriesTBL().then(() => {
                                                this.updateSeriesDR().then(() => {
                                                    this.updateSeriesGC()
                                                });                 
                                            });                 
                                        });
                                    });
                                });
                            });
                        });
                    })
                }) */
            },
            location: function(newVal, oldVal) {
                this.location = newVal;
            },
            start: function(newVal, oldVal) {
                this.start = moment(newVal).format('YYYY-MM-DD');
            },
            end: function(newVal, oldVal) {
                this.end = moment(newVal).format('YYYY-MM-DD');
            },
        },

        props: ['site', 'location', 'start', 'end'],

		data() {
			return {
                // select_all:'no',
                chart_type:['Analytic Table','Analytic By Hours','Number of Feedback Received','Nature Breakdown','Nature of Trigger','Trigger By Locations','Daily Representation','Graphical Chart'],
                // chart_types:['Analytic Table','Analytic By Hours','Number of Feedback Received','Nature Breakdown','Nature of Trigger','Trigger By Locations','Daily Representation','Graphical Chart'],
                chart_types:[
                    {
                        title: 'Analytic Table', value: true
                    }, {
                        title: 'Analytic By Hours', value: true
                    }, {
                        title: 'Number of Feedback Received', value: true
                    }, {
                        title: 'Nature Breakdown', value: true
                    }, {
                        title: 'Nature of Trigger', value: true
                    }, {
                        title: 'Trigger By Locations', value: true
                    }, {
                        title: 'Daily Representation', value: true
                    }, {
                        title: 'Graphical Chart', value: true
                    }
                ],

                error_message: null,
                popshowDismissibleAlert: false,

				organization_logo : '',
                site_logo : '',
                main_logo : '',

                // table vars 
                tableColumns: [
                    { key: 'legend', label: '', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'trigger_count', label: 'Trigger Count', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'attend_time', label: 'Attend Time (Average)', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'resolution', label: 'Resolution', sortable: false , thStyle:  {width: '25%'}},
                ],
                items  : [],
                webUrl : process.env.VUE_APP_SERVER_URL,
                data : [
                    {
                        legend: '8am - 10pm',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: '24 Hours',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: 'Total Impressions',
                        trigger_count: '0%'
                    }
                ],

                // Hourly 1
                themeColor: '#FFFFFF',
                seriesH1:[],
                optionsH1: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Hourly 2
                seriesH2:[],
                optionsH2: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#ffffff'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Number Of Feedback Received
                showNFR:false,
                seriesNFR: [],
                chartOptionsNFR: {
                    labels: ['Happy', 'Unhappy'],
                    stroke: {
                        width: 0,
                        colors: undefined
                    },
                    chart: {
                        type: 'donut',
                        animations: {
                            enabled: false
                        }
                    },
                    colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.info, $themeColors.secondary],
                    legend: {
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                    dataLabels: { 
                        enabled: true,
                        formatter: function (val, opt) {
                            return Number((val).toFixed(2))+"%"
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '22px',
                                        fontFamily: 'Verdana',
                                        color: '#000000',
                                        offsetY: -10
                                    },
                                    value: {
                                        show: true,
                                        fontFamily: 'Verdana',
                                        color: '#000000',
                                        fontSize: '25px',
                                        fontWeight: 'bold',
                                        offsetY: 16,
                                        formatter: function (val) {
                                            return val
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        color: '#000000',
                                        fontSize: '30px',
                                        formatter: function (w) {
                                            return w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)
                                        }
                                    }
                                }
                            }
                        }
                    },
                },

                // Nature BreakDown
                showNBD:false,
                seriesNBD:[],
                optionsNBD: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#ffffff'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Nature of Trigger
                showNT:false,
                seriesNT: [
                    {
                        data: []
                    }
                ],
                optionsNT: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: ['#ffffff','#000000'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '30%',
                            // endingShape: 'rounded',
                            borderRadius: 3,
                            borderRadiusApplication: 'end'
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: ['#000000'],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: ['#000000'],
                                }
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            show: true,
                            style: {
                                colors: '#202124',
                            },
                            type: 'numeric',
                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124','#202124','#202124','#202124','#202124','#202124','#202124','#202124'],
                            },
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#202124'],
                        },
                    },
                },

                // Trigger By Locations
                showTBL:false,
                seriesTBL: [
                    {
                        data: []
                    }
                ],
                optionsTBL: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: ['#ffffff','#000000'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '30%',
                            // endingShape: 'rounded',
                            borderRadius: 4,
                            borderRadiusApplication: 'end'
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            show: true,
                            style: {
                                colors: '#000000',
                            },
                            type: 'numeric',
                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000'],
                            },
                            // rotate: -45,
                            // formatter: function(val) {
                            //     return val.length > 10 ? val.substring(0, 15) + '...' : val;
                            // }
                        }
                    },
                    
                    tooltip: {
                    theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function (val) {
                                    return ''
                                }
                            }
                        }
                        
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                },

                // Daily Representation
                showDR:false,
                seriesDR: [{
                    name: 'Happy',
                    data: [],
                }, {
                    name: 'Unhappy',
                    data: [],
                }],
                chartOptionsDR: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: false,
                        curve: 'straight',
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        fontSize: '14px',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            show: true,
                            style: {
                                colors: '#000000',
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#000000'],
                            }
                        }
                    },
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    tooltip: {
                    theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: function (val) {
                                return ''
                            }

                            }
                        }
                        
                    },
                    colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.warning, $themeColors.info],
                },

                // Graphical Chart 
                graphicalData : []
			}
		},
		methods : {	
            openModel(){
                this.$refs['chart-modal'].show();
            },

            resetModal(){
                // this.select_all = 'no';
                // this.chart_type = ['Analytic Table','Analytic By Hours','Number of Feedback Received','Nature Breakdown','Nature of Trigger','Trigger By Locations','Daily Representation','Graphical Chart'];
                this.chart_types = [
                    {
                        title: 'Analytic Table', value: true
                    }, {
                        title: 'Analytic By Hours', value: true
                    }, {
                        title: 'Number of Feedback Received', value: true
                    }, {
                        title: 'Nature Breakdown', value: true
                    }, {
                        title: 'Nature of Trigger', value: true
                    }, {
                        title: 'Trigger By Locations', value: true
                    }, {
                        title: 'Daily Representation', value: true
                    }, {
                        title: 'Graphical Chart', value: true
                    }
                ];

                this.popshowDismissibleAlert = false;
                this.error_message = null;
                this.$refs['chart-modal'].hide();
            },

            // selectAllGraph(e){
            //     if(e == 'yes') {
            //         this.chart_type = this.chart_types
            //     } else {
            //         this.chart_type = [];
            //     }
            // },

            // updateSelectAll(){
            //     if(this.chart_type.length == this.chart_types.length){
            //         this.select_all = 'yes';
            //     } else {
            //         this.select_all = 'no';
            //     }
            // },

            getChartPDF(bvModalEvt){
                bvModalEvt.preventDefault()

                var flag = this.chart_types.filter(item => item.value === false)

                if(flag.length != this.chart_types.length){
                    this.popshowDismissibleAlert = false;
                    this.$refs['chart-modal'].hide();
                    this.exportFile();
                } else {
                    this.popshowDismissibleAlert = true;
                    this.error_message = 'Please Select Chart.';
                }
            },
            
            exportFile(){
                // const routeData = this.$router.resolve({name: 'export-pdf', query: {site: this.site._id, location: this.location._id, start: moment(this.start).format('YYYY-MM-DD'), end: moment(this.end).format('YYYY-MM-DD')}});
                // window.open(routeData.href, '_blank');
                // let route = this.$router.resolve({ path: "/export-pdf/"+this.site._id+"/"+this.location._id+"/"+moment(this.start).format('YYYY-MM-DD')+"/"+moment(this.end).format('YYYY-MM-DD') });
                var tempLocation = null;
                if(this.location){
                    if(this.location._id == null){
                        tempLocation = 'null';
                    } else {
                        tempLocation = this.location._id;
                    }
                }

                var charts = this.chart_types.filter(item => item.value === true).map(item => item.title);

                let route = this.$router.resolve({ 
                    name: 'export-pdf',
                    params: {
                        site : this.site ? this.site._id : null,
                        location : tempLocation,
                        start : moment(this.start).format('YYYY-MM-DD'),
                        end : moment(this.end).format('YYYY-MM-DD'),
                        site_name : this.site ? this.site.site_name : null,
                        location_name : this.location ? this.location.name : '',
                        date_format : this.site ? this.site.pdf_date_format : null,
                        chart_type : charts.toString()
                    }
                });

                // console.log(this.location)
                // console.log(route)
                window.open(route.href, '_blank');
            },

			// Export PDF functions
            exportPDF() {
                Bus.$emit('showLoader');
                this.getHeaderImages().then(() => {
                    // this.$refs.refUserListTable.refresh();
                    this.dataList().then(() => {
                        this.updateSeriesH1().then(() => {
                            this.updateSeriesH2().then(() => {
                                this.updateSeriesNFR().then(() => {
                                    this.updateSeriesNBD().then(() => {
                                        this.updateSeriesNT().then(() => {
                                            this.updateSeriesTBL().then(() => {
                                                this.updateSeriesDR().then(() => {
                                                    this.updateSeriesGC().then(() => {
                                                        this.$refs.html2Pdf.generatePdf();
                                                    });                 
                                                });                 
                                            });                 
                                        });
                                    });
                                });
                            });
                        });
                    })
                })
            },

            onProgress(event) {
                if(event == 100){
                    Swal.fire({
                        position : 'center',
                        icon     : 'success',
                        title    : 'PDF Downloaded.',
                        timer    : 1500,
                        showConfirmButton : false
                    });
                    Bus.$emit('hideLoader');
                }
            },

            async beforeDownload ({ html2pdf, options, pdfContent }) {
                options.margin = .3;
                options.useCORS = true;
                // options.image = { type: 'jpeg', quality: 1 };

                // await html2pdf().set(options);
                /* await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    }
                }).save(); */
                
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    } 
                }).save()
            },

            getHeaderImages(){
                if(this.site._id != 'all-site'){
                    return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site ? this.site._id : null
                        },
                        api: '/api/feedback-panel-logo'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } 
                        else {
                            this.organization_logo = this.$store.getters.getResults.data.organization_logo;
                            this.site_logo = this.$store.getters.getResults.data.site_logo;
                            this.main_logo = this.$store.getters.getResults.data.main_logo;
                        }
                    });
                }
            },

            // table get data
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
					    location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
					    end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-table'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.data = this.$store.getters.getResults.data;
                        return this.data;
                    }
                });
            },

            // Hourly 1
            updateSeriesH1() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-pie-8-10'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.optionsH1.labels = this.$store.getters.getResults.data.labels
                        this.seriesH1 = this.$store.getters.getResults.data.series;
                        
                        this.optionsH1.colors = [$themeColors.warning, $themeColors.success];
                        if(typeof this.$refs['pie_chartH1'] != 'undefined'){
                            this.$refs.pie_chartH1.updateSeries(this.seriesH1, true);
                            this.$refs.pie_chartH1.updateOptions(this.optionsH1, true);    
                        }
                    }
                });
            },

            // Hourly 2
            updateSeriesH2() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-pie-24'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.optionsH2.labels = this.$store.getters.getResults.data.labels
                        this.seriesH2 = this.$store.getters.getResults.data.series;
                        
                        this.optionsH2.colors = [$themeColors.primary, $themeColors.info];
                        if(typeof this.$refs['pie_chartH2'] != 'undefined'){
                            this.$refs.pie_chartH2.updateSeries(this.seriesH2, true);
                            this.$refs.pie_chartH2.updateOptions(this.optionsH2, true);    
                        }                        
                    }
                });
            },

            // Number Of Feedback Received
            updateSeriesNFR() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-doughnut'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesNFR[0] = parseInt(this.$store.getters.getResults.data[0].value);
                        this.seriesNFR[1] = parseInt(this.$store.getters.getResults.data[1].value);
                        
                        if (typeof this.$refs['doughnut_chart'] != 'undefined') {
                            
                            this.$refs.doughnut_chart.updateSeries(this.seriesNFR, true);
                            this.$refs.doughnut_chart.updateOptions(this.chartOptionsNFR, true);
                            if ((this.seriesNFR[0] + this.seriesNFR[1]) > 0) {
                                this.showNFR = true;
                            }else {
                                this.showNFR = false;
                            }
                        }
                    }
                });
            },

            // Nature BreakDown
            updateSeriesNBD() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-bar'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {

                        this.optionsNBD.labels = this.$store.getters.getResults.data.labels
                        this.seriesNBD = this.$store.getters.getResults.data.series;

                        this.optionsNBD.colors = [];

                        if (this.seriesNBD && this.seriesNBD.length > 0) {

                            for (var i = 0; i < (this.seriesNBD.length); i++) {
                            
                                var randomColor = Math.floor(Math.random()*16777215).toString(16);
                                
                                if (randomColor.length == 6) {                        
                                    this.optionsNBD.colors.push('#'+randomColor);
                                }else{
                                    i = i - 1;
                                }
                            }    
                        }

                        if (this.optionsNBD.labels.length > 0) {
                            this.showNBD = true;
                            this.$refs.nbd_chart.updateSeries(this.seriesNBD, true);
                            this.$refs.nbd_chart.updateOptions(this.optionsNBD, true);
                        }else{
                            this.showNBD = false;
                        }
                        
                    }
                });
            },

            // Nature of Trigger
            updateSeriesNT() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                        toilet   : this.toilet
                    },
                    api: '/api/feedback-request-analytics-bar-3'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesNT = this.$store.getters.getResults.data.series;
                        this.optionsNT.xaxis.categories = this.$store.getters.getResults.data.labels;
    
                        this.optionsNT.colors = [$themeColors.info, $themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.warning]

                        this.optionsNT.xaxis.labels.style.colors = '#000000'
                        
                        if (this.$store.getters.getResults.data.labels.length > 0) {
    
                            this.showNT = true;
                        }else{
                            this.showNT = false;
                        }    
                        this.$refs.bar_chartNT.updateSeries(this.seriesNT, true);
                        this.$refs.bar_chartNT.updateOptions(this.optionsNT, true);
                        
                    }
                });
            },

            // Trigger by Locations
            updateSeriesTBL() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                        toilet   : this.toilet
                    },
                    api: '/api/feedback-request-toilet-analytics'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesTBL = this.$store.getters.getResults.data.series;
                        this.optionsTBL.xaxis.categories = this.$store.getters.getResults.data.labels;
    
                        this.optionsTBL.colors = [$themeColors.success,$themeColors.warning,$themeColors.info,$themeColors.primary]
                        
                        if (this.$store.getters.getResults.data.labels.length > 0) {
    
                            this.showTBL = true;
                        }else{
                            this.showTBL = false;
                        }
    
                        this.$refs.loc_chart.updateSeries(this.seriesTBL, true);
                        this.$refs.loc_chart.updateOptions(this.optionsTBL, true);
                        
                    }
                });
            },

            // Daily Representation
            updateSeriesDR() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-line-area'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.chartOptionsDR.xaxis.categories = this.$store.getters.getResults.data.categories;
                        this.seriesDR[0].data = this.$store.getters.getResults.data.happy;
                        this.seriesDR[1].data = this.$store.getters.getResults.data.unhappy;
    
                        this.showDR = false;
                        var temparr = [];
                        temparr = temparr.concat(this.$store.getters.getResults.data.happy,this.$store.getters.getResults.data.unhappy);
                        
                        for (var i = 0; i < temparr.length; i++) {
                            
                            if (temparr[i] > 0) {
                                this.showDR = true;
                                break;
                            }
                        }
    
                        this.$refs.line_chartDR.updateSeries(this.seriesDR, true);
                        this.$refs.line_chartDR.updateOptions(this.chartOptionsDR, true);
                    }
                });
            },

            // Graphical Chart 
            updateSeriesGC() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site._id : null,
                        location : this.location ? this.location._id : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-graphical-charts'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        /* // this.graphicalData = this.$store.getters.getResults.data
                        var tempGD = [];
                        for(var i = 0; i < this.$store.getters.getResults.data.length; i++){
                            tempGD.push({
                                data: this.$store.getters.getResults.data[i].data,
                                label: this.$store.getters.getResults.data[i].label,
                                series: [this.$store.getters.getResults.data[i].percentage],
                                chartOptions: {
                                    colors: [this.$store.getters.getResults.data[i].color],
                                    chart: {
                                        height: 180,
                                        type: 'radialBar',
                                        animations: {
                                            enabled: false
                                        }
                                    },
                                    stroke: {
                                        show: true,
                                        curve: 'smooth',
                                        lineCap: 'butt',
                                        colors: ['#000'],
                                        width: 2,
                                        dashArray: 0,      
                                    },
                                    plotOptions: {
                                        radialBar: {
                                            hollow: {
                                                margin: 15,
                                                size: '65%',
                                                image: this.$store.getters.getResults.data[i].image,
                                                imageWidth: 55,
                                                imageHeight: 55,
                                                imageClipped: false
                                            },
                                            dataLabels: {
                                                show: true,
                                                name: {
                                                    show: false,
                                                },
                                                value: {
                                                    show: true,
                                                    color: '#000',
                                                    offsetY: 80,
                                                    fontSize: '15px',
                                                    fontFamily: 'Verdana',
                                                }
                                            }
                                        }
                                    },
                                }
                            })
                        }
                        // console.log(tempGD)
                        this.graphicalData = tempGD; */

                        this.graphicalData = this.$store.getters.getResults.data
                    
                        return this.graphicalData
                    }
                });
            },
		},

		mounted(){
		}
	
	}
</script>
<style scoped>
.dark-text{
    color: black !important;
}
.dark-layout .apexcharts-canvas .apexcharts-yaxis-label, .dark-layout .apexcharts-canvas .apexcharts-xaxis-label, .dark-layout .apexcharts-canvas .apexcharts-tooltip-text, .dark-layout .apexcharts-canvas .apexcharts-datalabel-label {
    fill: black!important;
}

/* [dir=ltr] .custom-checkbox.custom-control, [dir=ltr] .custom-radio.custom-control {
    padding-left: 1.8rem;
    padding-bottom: 8px !important;
} */

.custom-padding + div {
    padding-bottom: 8px !important;
}
</style>