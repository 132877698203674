<template>
    <div>
        <template>
            <div id="breadcrumb-alignment" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb :items="breadCrumb()" />
                </div>
            </div>
        </template>

        <b-card no-body>
            <b-card-body class="pb-0">
                <b-row>
                    <b-col cols="12" :md="siteData && siteData._id != 'all-site' ? 10 : 12">
                        <b-row>
                            <b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                                <v-select :clearable="false"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="sites" label="site_name" class="w-100"
                                    placeholder="Project Site" v-model="siteData"
                                    @input="allLocations(); location='';"
                                >
                                    <template #selected-option="{ site_name }">
                                        <span v-if="site_name.length < 23">{{site_name}}</span>
                                        <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                                    </template>
                                </v-select>
                            </b-col>

                            <b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                                <b-form-group class="common_margin_bottom_zero">
                                    <v-select :clearable="false"
                                        v-model="location"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="name" :options="locationList"
                                        placeholder="Select Location"
                                    />
                                </b-form-group>
                            </b-col>


                            <b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                                <div class="d-flex align-items-center">                                
                                    <flat-pickr
                                        v-model="date_range"
                                        :config="date_config"
                                        class="form-control flat-picker"
                                        placeholder="Select Date Range"
                                        @on-change="setDate()"
                                    />
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="2" class="mobile_tab_max_width_flex" v-if="(checkPermission($route.name, 'Export PDF')) && (siteData && siteData._id != 'all-site')">
                        <ExportReport :site="siteData" :location="location" :start="start" :end="end"/>
                    </b-col> 
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" md="12" class="mobile_tab_max_width_flex">
                        <b-form-group v-slot="{ ariaDescribedby }" class="d-flex" style="justify-content: center !important;">
                            <b-form-radio-group
                            v-model="chart_type"
                            :options="chart_types"
                            class="demo-inline-spacing mb-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                            :aria-describedby="ariaDescribedby"
                            style="display: block !important;"
                            />
                        </b-form-group>

                        <!-- <b-form-radio-group
                        v-model="chart_type"
                        :options="chart_types"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                        /> -->
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-row>
            <b-col md="12" class="pb-2" v-if="chart_type == 'Analytic Table'">
                <b-card no-body class="h-100">
                    <AnalyticsTable :siteData="siteData" :location="location" :start="start" :end="end" />
                </b-card>
            </b-col>

            <b-col md="6" class="pb-2" v-if="chart_type == 'Analytic By Hours'">
                <b-card no-body class="h-100">
                    <Hourly1 :siteData="siteData" :location="location" :start="start" :end="end" :legend_position="'bottom'" />
                </b-card>
            </b-col>

            <b-col md="6" class="pb-2" v-if="chart_type == 'Analytic By Hours'">
                <b-card no-body class="h-100">
                    <Hourly2 :siteData="siteData" :location="location" :start="start" :end="end" :legend_position="'bottom'" />
                </b-card>
            </b-col>


            <b-col md="12" class="pb-2" v-if="chart_type == 'Number of Feedback Received'">
                <b-card no-body class="h-100">
                    <NumberOfFeedbackRecieved :siteData="siteData" :location="location" :start="start" :end="end" :legend_position="'bottom'" />
                </b-card>
            </b-col>

            <b-col md="12" class="pb-2" v-if="chart_type == 'Nature Breakdown'">
                <b-card no-body class="h-100">
                    <NatureBreakDown :siteData="siteData" :location="location" :start="start" :end="end" />
                </b-card>
            </b-col>

            <b-col md="12" class="pb-2" v-if="chart_type == 'Nature of Trigger'">
                <b-card no-body class="h-100">
                    <NatureofTrigger :siteData="siteData" :location="location" :start="start" :end="end" />
                </b-card>
            </b-col>

            <b-col md="12" class="pb-2" v-if="chart_type == 'Trigger By Locations'">
                <b-card no-body class="h-100">
                    <TriggerbyLocations :siteData="siteData" :location="location" :start="start" :end="end" />
                </b-card>
            </b-col>

            <b-col md="12" offset-md="0" class="pb-2" v-if="chart_type == 'Daily Representation'">
                <b-card no-body class="h-100">
                    <DailyRepresentation :siteData="siteData" :location="location" :start="start" :end="end" />

                </b-card>
            </b-col>

            <b-col md="12" offset-md="0" class="pb-2" v-if="chart_type == 'Graphical Chart'">
                <b-card no-body class="h-100">
                    <GraphicalChart :siteData="siteData" :location="location" :start="start" :end="end" />

                </b-card>
            </b-col>
            
        </b-row>
        
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BForm, BAlert, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BFormFile, BFormGroup, BFormCheckbox, BCardBody, BBreadcrumb, BFormRadio,BFormRadioGroup
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import moment from "moment-timezone";
    import flatPickr from 'vue-flatpickr-component';
    import AnalyticsTable from './AnalyticsTable.vue';
    import Hourly1 from './Hourly1.vue';
    import Hourly2 from './Hourly2.vue';
    import NumberOfFeedbackRecieved from './NumberOfFeedbackRecieved.vue';
    import NatureBreakDown from './NatureBreakDown.vue';
    import NatureofTrigger from './NatureofTrigger';
    import TriggerbyLocations from './TriggerByLocations';
    import DailyRepresentation from './DailyRepresentation';
    import GraphicalChart from './GraphicalChart';
    import ExportReport from './ExportReport';

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BForm, vSelect, 
            BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BFormFile, BFormGroup, BFormCheckbox, BBreadcrumb, flatPickr, 
            BFormRadio,BFormRadioGroup,AnalyticsTable, Hourly1, Hourly2,NumberOfFeedbackRecieved, NatureBreakDown, NatureofTrigger, TriggerbyLocations, 
            DailyRepresentation, GraphicalChart, ExportReport            
        },
        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                showDismissibleAlert:false,

                sites:[],
                siteData:null,
                items: [],

                locationList: [],
                location: '',

                // date vars
                yearData:[],
                year:moment(new Date()).format('YYYY'),
                //months:['January', 'February', 'March', 'April', 'May', 'June', 'July ', 'August', 'September', 'October', 'November', 'December'],
                //month:moment().format('MMMM'),
                start: moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
                end: moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'), 

                date_config:{ 
                  mode: 'range',
                  dateFormat: 'd M Y',
                  defaultDate: [
                    moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),
                    moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY')
                  ]
                },

                date_range: moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY')+' to '+ moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),

                analyticsData : {
                    
                    pieData_8_10:{
                        series: [0, 100],
                        labels: ['Completed', 'Incomplete']
                    }, 
                    pieData_24:{
                        series: [0, 100],
                        labels: ['Completed', 'Incomplete']
                    }
                },
                happy:1,
                unhappy:1,
                nbc:0,
                nbm:0,
                nbf:0,
                nbh:0,
                nbo:0,
                pie1:0,
                pie2:0,
                chart_type:'Analytic Table',
                chart_types:['Analytic Table','Analytic By Hours','Number of Feedback Received','Nature Breakdown','Nature of Trigger','Trigger By Locations','Daily Representation','Graphical Chart']
            }
        },
        methods : {   
            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;

                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);
                        
                        this.siteData = this.defaultSite(this.sites);
                        this.allLocations();
                        return this.sites;
                    }
                });
            },

            allLocations(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.siteData ? this.siteData._id : null,
                        type:'toilet'
                    },
                    api: '/api/all-locations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;                
                        this.locationList = data;

                        
                        var obj = {
                            _id:null,
                            name:'All Locations'
                        }
                        this.locationList.unshift(obj);
                        
                        this.location = this.locationList[0]
                        return this.locationList;
                    }
                });
            },

            breadCrumb(){
                var item = [{
                    to:{name:'client-dashboard'},
                    text: 'Dashboard',
                },{
                    to:null,
                    text: 'Feedback System',
                },{
                    to:null,
                    text:'Analytics',
                    active:true
                }];
                return item;
            }, 

            getDate(){
                var date = null;
                if (this.month == null && this.year == null) {
                    this.start = null;
                    this.end = null;
                }else if(this.month == null && this.year != null){
                    date = moment.utc().startOf('month').format('MM-DD');
                    var year = this.year;
                    date =  year.toString() + '-'+ date;
                    date = moment.utc(date).startOf('year').format('YYYY-MM-DD');
                    this.start = date;
                    this.end = moment(date).endOf('year').format('YYYY-MM-DD');
                }else if(this.month != null && this.year == null){
                    var year = moment.utc().startOf('month').format('YYYY');
                    date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
                    date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
                    this.start = date;
                    this.end = moment(date).endOf('month').format('YYYY-MM-DD');
                }else{
                    var year = this.year.toString();
                    date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
                    date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
                    this.start = date;
                    this.end = moment(date).endOf('month').format('YYYY-MM-DD');
                }
            },
            setDate(){
                
                var date = this.date_range.split(' to ');

                if (date[1]) {
                    this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
                    this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
                }else{

                    this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
                    this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
                }
            },

            calYear(){
                /*var year = moment().format('YYYY');
                var yearData = [];
                var start = parseInt(year) - 2;
                var end =  parseInt(year) + 5;

                for (var i = start ; i <= end; i++) {
                    yearData.push(i);
                }
                this.yearData = yearData;*/
            },
            

            // getAnalytics(){
            //     return this.$store.dispatch(POST_API, {
            //         data:{
            //             site      : this.siteData ? this.siteData._id : null,
            //          location : this.location ? this.location._id : null,
            //             start    : moment(this.start).format('YYYY-MM-DD'),
            //          end      : moment(this.end).format('YYYY-MM-DD'),
            //         },
            //         api: '/api/feedback-request-analytics'
            //     })
            //     .then(() => {
            //         if (this.$store.getters.containsErrors) {
            //             this.error_message = this.$store.getters.getErrors;
            //         } else {
            //             this.tableData    = this.$store.getters.getResults.data.tableData;
            //             this.pieData_8_10 = this.$store.getters.getResults.data.pieData_8_10;
            //             this.pieData_24   = this.$store.getters.getResults.data.pieData_24;
            //         }
            //     });
            // },
        },

        mounted(){


            var refresh = localStorage.getItem('refresh_analytics');
            
            if(refresh === 'false'){
                localStorage.setItem('refresh_analytics', 'true');  
                window.location.reload();
                //console.log('aya');
            }else{
                //console.log('aya1');
                this.allSites();
            }

            //this.calYear();
            //this.getDate();
        }    
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .dark-layout .apexcharts-canvas .apexcharts-yaxis-label, .dark-layout .apexcharts-canvas .apexcharts-xaxis-label, .dark-layout .apexcharts-canvas .apexcharts-tooltip-text, .dark-layout .apexcharts-canvas .apexcharts-datalabel-label {
         fill: white !important;
    }

    .dark-layout .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
         color: white !important;
    }

    .apexcharts-canvas .apexcharts-datalabel {
        fill: white !important;
        font-family: Helvetica, Arial, sans-serif;
    }

</style>
