<template>
  <div title="">
    <!-- <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart> -->
    
    <h4 class="text-center mt-2 mb-2">{{'Daily Representation'}}</h4>
    <vue-apex-charts
        ref="line_chart"
        type="area"
        :height="height"
        :options="chartOptions"
        :series="series"
        v-show="show"
    />

    <b-row v-show="show == false">

        <b-col md="12">
            <b-card no-body class="p-1">
                <h5 class="text-center">No Data Available</h5>
            </b-card>
        </b-col>

    </b-row>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { POST_API } from "../../../store/actions.type"
import { $themeColors } from '@themeConfig'
import moment from "moment-timezone";

export default {
    // components: { apexchart: VueApexCharts, },
    components: { VueApexCharts },

    props: ['siteData', 'location', 'start', 'end'],

    watch: { 
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        },
    },

    data() {
        return {
            height:500,
            show:false,
            series: [{
                name: 'Satisfied',
                data: [],
            }, {
                name: 'Unsatisfied',
                data: [],
            }],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: true,
                        tools:{
                          download:false // <== line to add
                        }
                    },

                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                    curve: 'straight',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                    labels: {
                        colors: ['#ffffff'],
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: [$themeColors.secondary],
                            }
                        }
                    },
                    yaxis: {

                        lines: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            style: {
                                colors: [$themeColors.secondary],
                            },

                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            },
                        }
                    },
                },
                xaxis: {
                    categories: [],
                    
                    labels: {
                        show: true,
                        style: {
                            colors: '#ffffff',
                        }
                    }
                },
                yaxis: {
                    tickAmount:1,
                    labels: {
                        show: true,
                        style: {
                            colors: ['#ffffff'],
                        },

                        type: 'numeric',
                        formatter: function(val) {
                            return parseInt(val).toFixed(0)
                        }
                    }
                },
                fill: {
                    opacity: 1,
                    type: 'solid',
                },
                tooltip: {
                  theme: 'dark',
                      x: {
                        show: false
                      },
                      y: {
                        title: {
                          formatter: function (val) {
                            return ''
                          }

                        }
                      }
                    
                },
                colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.warning, $themeColors.info],
            },
        }
    },

    methods: {
        updateSeries() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                },
                api: '/api/feedback-request-analytics-line-area'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.chartOptions.xaxis.categories = this.$store.getters.getResults.data.categories;
                    this.series[0].data = this.$store.getters.getResults.data.happy;
                    this.series[1].data = this.$store.getters.getResults.data.unhappy;

                    this.show = false;
                    var temparr = [];
                    temparr = temparr.concat(this.$store.getters.getResults.data.happy,this.$store.getters.getResults.data.unhappy);
                    
                    for (var i = 0; i < temparr.length; i++) {
                        
                        if (temparr[i] > 0) {
                            this.show = true;
                            break;
                        }
                    }

                    this.$refs.line_chart.updateSeries(this.series, true);
                    this.$refs.line_chart.updateOptions(this.chartOptions, true);
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.updateSeries();
            }
        }
    },

    mounted() {
        this.updateSeries().then(() => {
            this.$refs.line_chart.updateSeries(this.series, true);
            this.$refs.line_chart.updateOptions(this.chartOptions, true);
        });
    }

}
</script>
