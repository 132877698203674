<template>
    <div>
        <h4 class="text-center mt-2 mb-2"><b>{{'Nature of Trigger'}}</b></h4>
        <vue-apex-charts
            ref="bar_chart"
            type="bar"
            :height="height"
            :options="options"
            :series="series"
            v-show="show"
        />

        <b-row v-show="show == false">

            <b-col md="12">
                <b-card no-body class="p-1">
                    <h5 class="text-center">No Data Available</h5>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";

export default {
    name: 'BarChart',
    components: { VueApexCharts },

    props: ['siteData', 'location', 'start', 'end'],

    watch: {
        siteData: function(newVal, oldVal) {
            this.filterData();
        },
        location: function(newVal, oldVal) {
            this.filterData();
        },
        start: function(newVal, oldVal) {
            this.filterData();
        },
        end: function(newVal, oldVal) {
            this.filterData();
        }
    },

    data(){
        return{
            show:false,
            height:500,
            series: [
                {
                    data: []
                }
            ],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#ffffff','#000000'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '60%',
                        // endingShape: 'rounded',
                        borderRadius: 4,
                        borderRadiusApplication: 'end'
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: [$themeColors.secondary],
                            }
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: [$themeColors.secondary],
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                    textAnchor: 'start',
                    style: {
                        colors: [$themeColors.light]
                    },
                    formatter: function (val, opt) {
                        return val > 0 ? /* opt.w.globals.labels[opt.dataPointIndex] + ":  " +  */val : ''
                    },
                },

                xaxis: {
                    categories: [],
                    tickAmount:1,
                    labels: {
                        show: true,
                        style: {
                            colors: '#ffffff',
                        },
                        type: 'numeric',
                        formatter: function(val) {
                            return parseInt(val).toFixed(0)
                        },
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
                        },
                        // rotate: -45,
                        // formatter: function(val) {
                        //     return val.length > 10 ? val.substring(0, 15) + '...' : val;
                        // }
                    }
                },
                
                tooltip: {
                    enabled: true,
                   theme: 'dark',
                      x: {
                        show: false
                      },
                      y: {
                        title: {
                          formatter: function (val) {
                            return ''
                          }

                        }
                      } 
                    
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'bottom',
                    fontFamily: 'Montserrat',
                    labels: {
                        colors: ['#ffffff'],
                    },
                }
            }

        }
    },

    methods: {
        updateSeries() {
            return this.$store.dispatch(POST_API, {
                data:{
                    site   	 : this.siteData ? this.siteData._id : null,
                    location : this.location ? this.location._id : null,
                    start    : moment(this.start).format('YYYY-MM-DD'),
                    end      : moment(this.end).format('YYYY-MM-DD'),
                    role     : this.$store.getters.currentUser.role,
                    om_sites : this.$store.getters.currentUser.om_sites,
                    report   : 'periodic',
                    toilet   : this.toilet
                },
                api: '/api/feedback-request-analytics-bar-3'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.series = this.$store.getters.getResults.data.series;

                    this.options = {...this.options, ...{
                        xaxis : {
                            categories : this.$store.getters.getResults.data.labels
                        },
                        colors : [$themeColors.info, $themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.warning]
                    }} 
                    

                    
                    if (this.$store.getters.getResults.data.labels.length > 0) {

                        this.show = true;
                    }else{
                        this.show = false;
                    }

                    this.$refs.bar_chart.updateSeries(this.series, true);
                    this.$refs.bar_chart.updateOptions(this.options, true);
                    /* this.$refs.bar_chart.exec('bar_chart', 'updateOptions', {
                        tooltip: {
                            theme: 'dark',
                            x: {
                                show: false
                            },
                            y: {
                                title: {
                                    formatter: function (val) {
                                        return ''
                                    }
                                }
                            }                            
                        },
                    }, false, true);               
                    this.$refs.bar_chart.render()      */
                }
            });
        },
        filterData() {
            if (this.siteData != null && this.location != null && this.start != '' && this.end != '') {
                this.updateSeries();
            }
        }
    },

    mounted() {
        this.updateSeries();
    }
}
</script>

<style>

</style>